import {
  ConfigScopesMap,
  CustomerStringKeyMap,
  StringKeyMap,
  ValueFromMap,
} from "../types/configs";

export const configScopesMap: ConfigScopesMap = {
  ACCESS: "access",
  APP: "libryApp",
  ASSIST: "assist",
  BRANCH: "branch",
  BUTLER: "butler",
  CUSTOMER: "customer",
  DOOR_ACCESS: "doorAccess",
  GATES: "gates",
  GENERAL: "general",
  INSPIRE: "inspire",
  LIBRARY_APP: "libraryApp",
  POSTER: "poster",
};

export const valueFromMap: ValueFromMap = {
  ALARM: "alarm",
  APP: "libryapp",
  AUDIO: "audio",
  AUDIOFILE: "audiofile",
  BRANCH: "branch",
  BUTLER: "butler",
  CONTROLUNIT: "controlunit",
  CUSTOMER: "customer",
  DOOR: "door",
  DOORACCESS: "dooraccess",
  DOORPANEL: "doorpanel",
  EVENTSTATE: "eventstate",
  GATE: "gate",
  GENERAL: "general",
  INSPIRE: "inspire",
  IO: "io",
  LIBRARY_APP: "libraryapp",
  LIGHT: "light",
  OVERRIDE: "override",
  PEOPLE_COUNTER: "people_counter",
  SORTBIN: "sortbin",
};

export const stringKeyMap: StringKeyMap = {
  ACTIVATE_ALARM_SYSTEM: "activateAlarmSystem",
  ACTIVATE_EAS: "activateEas",
  ACTIVATE_PORT: "activatePort",
  ADD_TO_CHECKLIST_ENABLED: "addToChecklistEnabled",
  AFI_IN_STOCK: "afiInStock",
  AFI_ON_LOAN: "afiOnLoan",
  ALT_KEY1_VALUE: "altKey1Value",
  ALT_KEY2_VALUE: "altKey2Value",
  ANALYTICS_TRACKING_ID: "analyticsTrackingId",
  ANALYTICS_VIEW_ID: "analyticsViewId",
  ANIMATION: "animation",
  AUDIO_FILENAME: "audioFileName",
  AUDIO_LANGUAGE: "audioLanguage",
  AUDIO_SIZE: "audioSize",
  AUDIO_TYPE: "audioType",
  AUDIO_URL: "audioUrl",
  AUTH_NO_PASSWORD: "authNoPassword",
  AUTOMATIC_RESET: "automaticReset",
  AVAILABLE_FILTER: "availableFilter",
  BARCODE_ICON_PLACEMENT: "barcodeIconPlacement",
  BARCODE_MATERIALS: "barcodeMaterials",
  BEACONDATE: "beaconDate",
  BEACONID: "beaconId",
  BIN_KEY: "binKey",
  BOX: "box",
  CHECKIN: "checkin",
  CHECKIN_BOX_ADDRESS: "checkinBoxAddress",
  CHECKIN_BOX_ENABLED: "checkinBoxEnabled",
  CHECKIN_BOX_PORT: "checkinBoxPort",
  CHECKIN_BOX_TYPE: "checkinBoxType",
  CHECKOUT: "checkout",
  CHECKOUT_NO_PASSWORD: "checkoutNoPassword",
  COLOR_ACCENTS: "colorAccents",
  COLOR_DARK: "colorDark",
  COLOR_LIGHT: "colorLight",
  CREATE_LOANER: "createLoaner",
  CREATE_LOANER_URL: "createLoanerUrl",
  DEACTIVATE_PORT: "deactivatePort",
  DEFAULT_SORT_BIN: "defaultSortBin",
  DOCUMENT_DISPENSER_PASSWORD: "documentDispenserPassword",
  DOCUMENT_DISPENSER_URL: "documentDispenserUrl",
  DOCUMENT_DISPENSER_USERNAME: "documentDispenserUsername",
  DOOR_ID: "doorId",
  EMERGENCY_SORT_BIN: "emergencySortBin",
  ENABLE_EMAIL_RECEIPTS: "enableEmailReceipts",
  EVENT_URL: "eventUrl",
  HAS_DOOR_SENSOR: "hasDoorSensor",
  ICON: "icon",
  IDLE_SELECT_NEXT_SECONDS: "idleSelectNextSeconds",
  IDLE_TIMEOUT_SECONDS: "idleTimeoutSeconds",
  INACTIVITY_TIMEOUT: "inactivityTimeout",
  INCLUDE_SEARCH_RESULTS_WITHOUT_COVERS: "includeSearchResultsWithoutCovers",
  INFORMATION: "information",
  INFORMATION_URL: "informationUrl",
  INPUTS: "inputs",
  INSPIRATION_URL: "inspirationUrl",
  KEYBOARD_TYPE_PASSWORD: "keyboardTypePassword",
  KEYBOARD_TYPE_USERNAME: "keyboardTypeUsername",
  LANGUAGE: "language",
  LANGUAGE_LIST: "languageList",
  LARGE_BOOKS: "largeBooks",
  LATITUDE: "latitude",
  LOAD_ONLY_AVAILABLE: "loadOnlyAvailable",
  LOG_ME_IN: "logMeIn",
  LONGITUDE: "longitude",
  MAJOR: "major",
  MAP_ENABLED: "mapEnabled",
  MAXIMUM_ID_LENGTH: "maximumIDLength",
  MAXIMUM_PIN_LENGTH: "maximumPinLength",
  MAX_SEARCH_RESULTS: "maxSearchResults",
  MENU_POSITION: "menuPosition",
  MINIMUM_ID_LENGTH: "minimumIDLength",
  MINIMUM_PIN_LENGTH: "minimumPinLength",
  MINOR: "minor",
  MOTOR_PORT: "motorPort",
  MY_PAGE: "myPage",
  NEWS_URL: "newsUrl",
  OFFLINE_CHECK_IN_ENABLED: "offlineCheckInEnabled",
  OFFLINE_CHECK_OUT_DEFAULT_DURATION: "offlineCheckOutDefaultDuration",
  OFFLINE_CHECK_OUT_ENABLED: "offlineCheckOutEnabled",
  OFFLINE_KEYBOARD_ENABLED: "offlineKeyboardEnabled",
  OFFLINE_STATE: "offlineState",
  OUTPUTS: "outputs",
  OVERRIDE_EVENT_STATE: "overrideEventState",
  PANEL_ID: "panelId",
  PASSWORD_INPUT_NUM_CHARS: "passwordInputNumChars",
  PATRON_ON_HOLD_START_DATE_DELAY_DAYS: "patronOnHoldStartDateDelayDays",
  PAYMENT: "payment",
  PAYMENT_PROVIDER: "paymentProvider",
  PICKUP_SHELF: "pickupShelf",
  PAYMENT_URL_FOR_QR_CODE: "paymentUrlForQrCode",
  PLACEMENT: "placement",
  PRINTER_FULL_CUT: "printerFullCut",
  PROFILE_FEES_ENABLED: "profileFeesEnabled",
  RADAR_PORT: "radarPort",
  RADIUS: "radius",
  RECEIPTS_MASK_USER_ID: "receiptsMaskUserId",
  RECEIPTS_SHOW_USER_ID: "receiptsShowUserId",
  RECEIPT_FORCE: "receiptForce",
  RESERVATION_ENABLED: "reservationEnabled",
  R_PORT_ID: "rportId",
  SCAN_MATERIAL: "scanMaterial",
  SEARCH: "search",
  SEARCH_BRANCH_ONLY: "searchBranchOnly",
  SEARCH_CATEGORIES: "searchCategories",
  SEARCH_FILTER_BRANCH_INIT: "searchFilterBranchInit",
  SEARCH_RESULT_VIEW: "searchResultView",
  SERIAL: "serial",
  SERVER_SIDE_AVAILABILITY_FILTERING: "serverSideAvailabilityFiltering",
  SIMPLE_MINIMAL_SEARCH_RESULT: "simpleMinimalSearchResult",
  SIP2_NS1: "sip2Ns1",
  SIP2_PATH: "sip2Path",
  SIP2_PWD: "sip2Pwd",
  SIP2_URL: "sip2Url",
  SIP2_USR: "sip2Usr",
  TEAMVIEWERID: "teamViewerId",
  TRANSLATION_KEY: "translationKey",
  USERNAME_INPUT_NUM_CHARS: "usernameInputNumChars",
  USE_ALERT_FIELD: "useAlertField",
  USE_SIP2: "useSip2",
  VIDEO_CHAT: "videoChat",
  VIDEO_CHAT_URL: "videoChatUrl",
  VOLUME: "volume",
  WAYFINDING_ENABLED: "wayfindingEnabled",
};

export const customerStringKeyMap: CustomerStringKeyMap = {
  AUTO_FETCH_BRANCHES: "autoFetchBranches",
  CUSTOMER_GROUP: "customerGroup",
  SERVICES: "services",
};
