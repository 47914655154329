import { Config, ConfigKeyLabelMap } from "../types/configs";

export const configKeys: Config = {
  customer: {
    autoFetchBranches: "customer.config.autoFetchBranches",
    customerGroup: "customer.config.customerGroup",
    services: "customer.config.services",
    templateEventStates: "customer.config.templateEventStates",
  },
  access: {
    activatePort: "access.unit.activatePort",
    alarms: "access.unit.alarms",
    altKey1Value: "access.commonConfig.altKey1Value",
    altKey2Value: "access.commonConfig.altKey2Value",
    audioFileName: "access.unit.audioFileName",
    audioLanguage: "access.unit.audioLanguage",
    audioSize: "access.unit.audioSize",
    audioType: "access.unit.audioType",
    audioUrl: "access.unit.audioUrl",
    audios: "access.unit.audios",
    deactivatePort: "access.unit.deactivatePort",
    disableKeyboard: "access.unit.disableKeyboard",
    doorId: "access.unit.doorId",
    doors: "access.unit.doors",
    eventStateColor: "access.unit.eventStateColor",
    hasDoorSensor: "access.unit.hasDoorSensor",
    inactivityTimeout: "access.commonConfig.inactivityTimeout",
    inputs: "access.unit.inputs",
    ioDirection: "access.unit.ioDirection",
    language: "access.commonConfig.language",
    languageList: "access.commonConfig.languageList",
    lights: "access.unit.lights",
    maximumIDLength: "access.commonConfig.maximumIDLength",
    maximumPinLength: "access.commonConfig.maximumPinLength",
    minimumIDLength: "access.commonConfig.minimumIDLength",
    minimumPinLength: "access.commonConfig.minimumPinLength",
    motorDuration: "access.unit.motorDuration",
    motorPort: "access.unit.motorPort",
    offlineMode: "access.commonConfig.offlineMode",
    offlineState: "access.commonConfig.offlineState",
    outputs: "access.unit.outputs",
    overrideEventState: "access.unit.overrideEventState",
    panelId: "access.unit.panelId",
    radarPort: "access.unit.radarPort",
    rportId: "access.unit.rportId",
    serial: "access.unit.serial",
    teamViewerId: "access.unit.teamViewerId",
    volume: "access.unit.volume",
  },
  assist: {
    eventUrl: "assist.commonConfig.eventUrl",
    inspirationUrl: "assist.commonConfig.inspirationUrl",
    newsUrl: "assist.commonConfig.newsUrl",
    paymentProvider: "assist.commonConfig.paymentProvider",
  },
  branch: {
    activateAlarmSystem: "branch.config.activateAlarmSystem",
    activateEas: "branch.config.activateEas",
    afiInStock: "branch.butlerConfig.afiInStock",
    afiOnLoan: "branch.butlerConfig.afiOnLoan",
    animation: "branch.sortBin.animation",
    authNoPassword: "branch.butlerConfig.authNoPassword",
    barcodeMaterials: "branch.butlerConfig.barcodeMaterials",
    beaconDate: "branch.libryAppConfig.beaconDate",
    beaconId: "branch.libryAppConfig.beaconId",
    binKey: "branch.sortBin.binKey",
    box: "branch.sortBin.box",
    checkin: "branch.libryAppConfig.checkin",
    checkoutNoPassword: "branch.butlerConfig.checkoutNoPassword",
    defaultSortBin: "branch.libryAppConfig.defaultSortBin",
    documentDispenserPassword: "branch.accessConfig.documentDispenser.password",
    documentDispenserUrl: "branch.accessConfig.documentDispenser.url",
    documentDispenserUsername: "branch.accessConfig.documentDispenser.username",
    eventStates: "branch.config.eventStates",
    icon: "branch.sortBin.icon",
    latitude: "branch.config.latitude",
    longitude: "branch.config.longitude",
    major: "branch.libryAppConfig.major",
    mapEnabled: "branch.butlerConfig.mapEnabled",
    minor: "branch.libryAppConfig.minor",
    product: "branch.sortBin.product",
    radius: "branch.config.radius",
    receiptForce: "branch.butlerConfig.receiptForce",
    reservationEnabled: "branch.butlerConfig.reservationEnabled",
    sip2Pwd: "branch.libryAppConfig.sip2.pwd",
    sip2Usr: "branch.libryAppConfig.sip2.usr",
    translationKey: "branch.sortBin.translationKey",
  },
  butler: {
    excludeBranchesFromReservations:
      "butler.commonConfig.excludeBranchesFromReservations",
    excludeBranchesFromGetHoldingsResponse:
      "butler.commonConfig.excludeBranchesFromGetHoldingsResponse",
    automaticReset: "butler.unit.automaticReset",
    availableFilter: "general.commonConfig.availableFilter",
    barcodeIconPlacement: "butler.unit.barcodeIconPlacement",
    checkin: "butler.unit.checkin",
    checkinBoxAddress: "butler.unit.checkinBoxAddress",
    checkinBoxEnabled: "butler.unit.checkinBoxEnabled",
    checkinBoxPort: "butler.unit.checkinBoxPort",
    checkinBoxType: "butler.unit.checkinBoxType",
    checkout: "butler.unit.checkout",
    createLoaner: "butler.unit.createLoaner",
    createLoanerUrl: "general.commonConfig.createLoanerUrl",
    defaultSortBin: "butler.unit.defaultSortBin",
    emergencySortBin: "butler.unit.emergencySortBin",
    enableEmailReceipts: "general.commonConfig.enableEmailReceipts",
    informationUrl: "general.commonConfig.informationUrl",
    videoChatUrl: "general.commonConfig.videoChatUrl",
    offlineCheckInEnabled: "general.commonConfig.offlineCheckInEnabled",
    offlineCheckOutEnabled: "general.commonConfig.offlineCheckOutEnabled",
    offlineCheckOutDefaultDuration:
      "general.commonConfig.offlineCheckOutDefaultDuration",
    language: "butler.commonConfig.language",
    languageList: "butler.commonConfig.languageList",
    logMeIn: "butler.unit.logMeIn",
    myPage: "butler.unit.myPage",
    offlineKeyboardEnabled: "general.commonConfig.offlineKeyboardEnabled",
    patronOnHoldStartDateDelayDays:
      "butler.commonConfig.patronOnHoldStartDateDelayDays",
    payment: "general.commonConfig.payment",
    paymentProvider: "butler.commonConfig.paymentProvider",
    paymentUrlForQrCode: "butler.commonConfig.paymentUrlForQrCode",
    pickupShelf: "butler.unit.pickupShelf",
    information: "butler.unit.information",
    videoChat: "butler.unit.videoChat",
    printerFullCut: "butler.unit.printerFullCut",
    profileFeesEnabled: "general.commonConfig.profileFeesEnabled",
    receiptsMaskUserId: "general.commonConfig.receiptsMaskUserId",
    receiptsShowUserId: "general.commonConfig.receiptsShowUserId",
    rportId: "butler.unit.rportId",
    scanMaterial: "butler.unit.scanMaterial",
    search: "butler.unit.search",
    searchFilterBranchInit: "general.commonConfig.searchFilterBranchInit",
    searchResultView: "general.commonConfig.searchResultView",
    serverSideAvailabilityFiltering:
      "general.commonConfig.serverSideAvailabilityFiltering",
    simpleMinimalSearchResult: "general.commonConfig.simpleMinimalSearchResult",
    sip2Pwd: "general.librarySystem.sip2.pwd",
    sip2Usr: "general.librarySystem.sip2.usr",
    sortBins: "butler.unit.sortBins",
    useAlertField: "butler.commonConfig.useAlertField",
    useSip2: "general.librarySystem.sip2.useSip2",
    wayfindingEnabled: "general.commonConfig.wayfindingEnabled",
  },
  doorAccess: {
    activate: "doorAccess.unit.activate",
  },
  gates: {
    placement: "gates.unit.placement",
  },
  general: {
    externalSystems: {
      enableDdbCmsForCustomer:
        "general.externalSystems.enableDdbCmsForCustomer",
      libraryHomePageUrl: "general.externalSystems.libraryHomePageUrl",
    },
    identity: {
      altLanguage: "general.identity.altLanguage",
      colorLight: "general.identity.colorLight",
      colorDark: "general.identity.colorDark",
      colorAccents: "general.identity.colorAccents",
      keyboardTypePassword: "general.identity.keyboardPassword",
      keyboardTypeUsername: "general.identity.keyboardUsername",
      passwordInputNumChars: "general.identity.passwordInputNumChars",
      usernameInputNumChars: "general.identity.usernameInputNumChars",
      language: "general.identity.language",
      languageList: "general.identity.languageList",
      logo: "general.identity.logo",
    },
    librarySystem: {
      bibliofilBaseUrl: "general.librarySystem.bibliofilBaseUrl",
      bibliofilBranchDepartmentMap:
        "general.librarySystem.bibliofil.branchDepartmentMap",
      bibliofilHttpBasicAuthPassword:
        "general.librarySystem.bibliofilHttpBasicAuthPassword",
      bibliofilHttpBasicAuthUser:
        "general.librarySystem.bibliofilHttpBasicAuthUser",
      bibliofilOauth2UserAuthenticationClientId:
        "general.librarySystem.bibliofilOauth2UserAuthenticationClientId",
      bibliofilOauth2UserAuthenticationUrl:
        "general.librarySystem.bibliofilOauth2UserAuthenticationUrl",
      bibliofilSearchUrl: "general.librarySystem.bibliofilSearchUrl",
      bibliofilCirculationAgencyId:
        "general.librarySystem.bibliofil.circulationAgencyId",
      bibliofilCirculationBaseUrl:
        "general.librarySystem.bibliofil.circulationBaseUrl",
      bibliofilCirculationHttpBasicAuthUser:
        "general.librarySystem.bibliofil.circulationHttpBasicAuthUser",
      bibliofilCirculationHttpBasicAuthPassword:
        "general.librarySystem.bibliofil.circulationHttpBasicAuthPassword",
      deichmanApiKey: "general.librarySystem.deichman.apiKey",
      deichmanOAuthBaseUrl: "general.librarySystem.deichman.oAuth.baseUrl",
      deichmanOAuthSecret: "general.librarySystem.deichman.oAuth.secret",
      deichmanOAuthUser: "general.librarySystem.deichman.oAuth.user",
      deichmanPaymentDemoAcquirer:
        "general.librarySystem.deichman.paymentDemo.acquirer",
      deichmanPaymentDemoBaseUrl:
        "general.librarySystem.deichman.paymentDemo.baseUrl",
      deichmanPaymentDemoDeleteFeeAtProvider:
        "general.librarySystem.deichman.paymentDemo.deleteFeeAtProvider",
      deichmanPaymentDemoEnv: "general.librarySystem.deichman.paymentDemo.env",
      deichmanPaymentDemoPassword:
        "general.librarySystem.deichman.paymentDemo.password",
      deichmanPaymentDemoUser:
        "general.librarySystem.deichman.paymentDemo.user",
      deichmanSearchUrl: "general.librarySystem.deichman.searchUrl",

      ciceroAgencyId: "general.librarySystem.cicero.agencyId",
      ciceroBaseUrl: "general.librarySystem.cicero.baseUrl",
      ciceroClientPassword: "general.librarySystem.cicero.clientPassword",
      ciceroClientUser: "general.librarySystem.cicero.clientUser",
      ciceroCatalogueBaseUrl: "general.librarySystem.cicero.catalogueBaseUrl",
      ciceroCatalogueUsername: "general.librarySystem.cicero.catalogueUsername",
      ciceroCataloguePassword: "general.librarySystem.cicero.cataloguePassword",
      ciceroCatalogueAgencyId: "general.librarySystem.cicero.catalogueAgencyId",
      ciceroCatalogueClientId: "general.librarySystem.cicero.catalogueClientId",

      fbsGateUser: "general.librarySystem.fbs.gateBasicUser",
      fbsGatePassword: "general.librarySystem.fbs.gateBasicPassword",
      fbsReservationUser: "general.librarySystem.fbs.reservationBasicUser",
      fbsReservationPassword:
        "general.librarySystem.fbs.reservationBasicPassword",
      fbsSsoUrl: "general.librarySystem.fbs.ssoUrl",
      fbsSsoClientId: "general.librarySystem.fbs.ssoClientId",

      fbsAgencyId: "general.librarySystem.fbs.agencyId",
      fbsBaseUrl: "general.librarySystem.fbs.baseUrl",
      fbsClientPassword: "general.librarySystem.fbs.clientPassword",
      fbsClientUser: "general.librarySystem.fbs.clientUser",
      fbsOneAndAHalfSearch: "general.librarySystem.fbs.sip2.oneAndAHalfSearch",
      fbsOpenSearchAgencyId: "general.librarySystem.fbs.openSearch.agencyId",
      fbsOpenSearchBaseUrl: "general.librarySystem.fbs.openSearch.baseUrl",
      fbsOpenSearchProfile: "general.librarySystem.fbs.openSearch.profile",
      libraryService: "general.librarySystem.libraryService",
      mikromarcBasicAuthCustomerId:
        "general.librarySystem.mikromarc.basicAuth.customerId",
      mikromarcBasicAuthPassword:
        "general.librarySystem.mikromarc.basicAuth.password",
      mikromarcBasicAuthUsername:
        "general.librarySystem.mikromarc.basicAuth.username",
      mikromarcLmsBaseUrl: "general.librarySystem.mikromarc.lmsBaseUrl",
      mikromarcSearchUrl: "general.librarySystem.mikromarc.searchUrl",
      quriaAlmaAxiell: "general.librarySystem.quria.almaAxiell",
      quriaApiBaseUrl: "general.librarySystem.quria.apiBaseUrl",
      quriaPlasBaseUrl: "general.librarySystem.quria.plas.baseUrl",
      quriaPlasClientId: "general.librarySystem.quria.plas.clientId",
      quriaPlasClientSecret: "general.librarySystem.quria.plas.clientSecret",
      quriaRecordAxiell: "general.librarySystem.quria.recordAxiell",
      quriaScopes: "general.librarySystem.quria.scopes",
      quriaLmsToSip2BranchIdMap:
        "general.librarySystem.quria.lmsToSip2BranchIdMap",
      quriaTenantId: "general.librarySystem.quria.tenantId",
      quriaUaaAxiell: "general.librarySystem.quria.uaaAxiell",
      sip2Ns1: "general.librarySystem.sip2.ns1",
      sip2Path: "general.librarySystem.sip2.path",
      sip2Pwd: "general.librarySystem.sip2.pwd",
      sip2Url: "general.librarySystem.sip2.url",
      sip2Usr: "general.librarySystem.sip2.usr",
      url: "general.librarySystem.url",
      useSip2: "general.librarySystem.sip2.useSip2",
      openBaseUrl: "general.librarySystem.openLibApi.lmsBaseUrl",
      openClientId: "general.librarySystem.openLibApi.clientId",
      openClientSecret: "general.librarySystem.openLibApi.clientSecret",
      openLibraryUrl: "general.librarySystem.openLibApi.libraryUrl",
      openLibraryUrlPassword:
        "general.librarySystem.openLibApi.libraryUrlPassword",
      openLibraryUrlUsername:
        "general.librarySystem.openLibApi.libraryUrlUsername",
      openSearchUrl: "general.librarySystem.openLibApi.searchUrl",
      openSearchUrlPassword:
        "general.librarySystem.openLibApi.searchUrlPassword",
      openSearchUrlUsername:
        "general.librarySystem.openLibApi.searchUrlUsername",
    },
    platform: {
      additionalTemporaryConfiguration: "general.platform.additionalTemporaryConfiguration"
    }
  },
  inspire: {
    addToChecklistEnabled: "inspire.commonConfig.addToChecklistEnabled",
    analyticsTrackingId: "inspire.commonConfig.analyticsTrackingId",
    analyticsViewId: "inspire.commonConfig.analyticsViewId",
    authNoPassword: "inspire.commonConfig.authNoPassword",
    idleSelectNextSeconds: "inspire.commonConfig.idleSelectNextSeconds",
    idleTimeoutSeconds: "inspire.commonConfig.idleTimeoutSeconds",
    includeSearchResultsWithoutCovers:
      "inspire.commonConfig.includeSearchResultsWithoutCovers",
    keyboardTypePassword: "inspire.commonConfig.keyboardTypePassword",
    keyboardTypeUsername: "inspire.commonConfig.keyboardTypeUsername",
    languageList: "inspire.commonConfig.languageList",
    largeBooks: "inspire.unit.largeBooks",
    loadOnlyAvailable: "inspire.unit.loadOnlyAvailable",
    maxSearchResults: "inspire.commonConfig.maxSearchResults",
    menuPosition: "inspire.unit.menuPosition",
    searchBranchOnly: "inspire.unit.searchBranchOnly",
    searchCategories: "inspire.unit.searchCategories",
    usernameInputNumChars: "inspire.commonConfig.usernameInputNumChars",
  },
  libryApp: {
    newsUrl: "libryApp.newsAndEvents.newsUrl",
    eventsUrl: "libryApp.newsAndEvents.eventsUrl",
    ipList: "libryApp.ipAddresses.ipList",
    uuid: "libryApp.checkinsAndCheckouts.uuid",
  },
  libraryApp: {
    languageList: "libraryApp.commonConfig.languageList",
  },
  poster: {},
};

export const configKeyLabels: ConfigKeyLabelMap = {
  customer: ["autoFetchBranches", "customerGroup", "services"],
  general: [
    "afiInStock",
    "afiOnLoan",
    "authNoPassword",
    "barcodeMaterials",
    "checkoutNoPassword",
    "keyboardTypePassword",
    "keyboardTypeUsername",
    "mapEnabled",
    "passwordInputNumChars",
    "receiptForce",
    "reservationEnabled",
    "usernameInputNumChars",
  ],
  access: [
    "activatePort",
    "altKey1Value",
    "altKey2Value",
    "audioFileName",
    "audioLanguage",
    "audioSize",
    "audioType",
    "audioUrl",
    "deactivatePort",
    "disableKeyboard",
    "doorId",
    "hasDoorSensor",
    "inactivityTimeout",
    "inputs",
    "language",
    "languageList",
    "maximumIDLength",
    "maximumPinLength",
    "minimumIDLength",
    "minimumPinLength",
    "motorPort",
    "offlineMode",
    "offlineState",
    "outputs",
    "overrideEventState",
    "panelId",
    "pinEnabled",
    "radarPort",
    "rportId",
    "teamViewerId",
    "serial",
    "volume",
  ],
  assist: ["eventUrl", "inspirationUrl", "newsUrl", "paymentProvider"],
  branch: [
    "activateAlarmSystem",
    "activateEas",
    "afiInStock",
    "afiOnLoan",
    "animation",
    "beaconDate",
    "beaconId",
    "binKey",
    "box",
    "checkin",
    "defaultSortBin",
    "documentDispenserPassword",
    "documentDispenserUrl",
    "documentDispenserUsername",
    "icon",
    "latitude",
    "longitude",
    "major",
    "minor",
    "radius",
    "sip2Pwd",
    "sip2Usr",
    "translationKey",
  ],
  butler: [
    "automaticReset",
    "availableFilter",
    "barcodeIconPlacement",
    "checkin",
    "checkinBoxAddress",
    "checkinBoxEnabled",
    "checkinBoxPort",
    "checkinBoxType",
    "checkout",
    "createLoaner",
    "createLoanerUrl",
    "defaultSortBin",
    "emergencySortBin",
    "enableEmailReceipts",
    "informationUrl",
    "videoChatUrl",
    "searchFilterBranchInit",
    "simpleMinimalSearchResult",
    "language",
    "languageList",
    "latitude",
    "logMeIn",
    "myPage",
    "offlineCheckInEnabled",
    "offlineCheckOutDefaultDuration",
    "offlineCheckOutEnabled",
    "offlineKeyboardEnabled",
    "patronOnHoldStartDateDelayDays",
    "payment",
    "paymentUrlForQrCode",
    "paymentProvider",
    "pickupShelf",
    "information",
    "videoChat",
    "printerFullCut",
    "profileFeesEnabled",
    "receiptsMaskUserId",
    "receiptsShowUserId",
    "rportId",
    "scanMaterial",
    "search",
    "searchFilterBranchInit",
    "searchResultView",
    "serverSideAvailabilityFiltering",
    "simpleMinimalSearchResult",
    "sip2Ns1",
    "sip2Path",
    "sip2Pwd",
    "sip2Url",
    "sip2Usr",
    "useAlertField",
    "useSip2",
    "wayfindingEnabled",
  ],
  gates: ["placement"],
  doorAccess: [],
  inspire: [
    "addToChecklistEnabled",
    "analyticsTrackingId",
    "analyticsViewId",
    "authNoPassword",
    "idleSelectNextSeconds",
    "idleTimeoutSeconds",
    "includeSearchResultsWithoutCovers",
    "keyboardTypePassword",
    "keyboardTypeUsername",
    "languageList",
    "largeBooks",
    "loadOnlyAvailable",
    "maxSearchResults",
    "menuPosition",
    "searchBranchOnly",
    "searchCategories",
    "usernameInputNumChars",
  ],
  libryApp: [],
  libraryApp: ["languageList"],
  poster: [],
};
